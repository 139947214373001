import * as React from 'react';
import Button, {ButtonProps} from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import {LinkProps, useHref, useLinkClickHandler} from "react-router-dom";

interface ButtonLinkProps
  extends ButtonProps,
    Pick<LinkProps, 'replace' | 'state' | 'to'> {
  children?: React.ReactNode;
}

const ButtonLink = React.forwardRef<HTMLButtonElement, ButtonLinkProps>(
  ({ onClick, replace = false, state, target, to, ...props }, ref) => {
    let href = useHref(to);
    let handleClick = useLinkClickHandler(to, { replace, state, target });

    return (
      <Button
        {...props}
        href={href}
        onClick={(event) => {
          onClick?.(event);
          if (!event.defaultPrevented) {
            handleClick(event as any);
          }
        }}
        ref={ref}
        target={target}
      />
    );
  }
);

export default function Root() {
  return (
    <>
      <Container className="p-3">
        <Container className="p-5 mb-4 bg-light rounded-3">
          <h1 className="header align-text-center">SAAS BP</h1>
          <div className="d-grid gap-2 col-8 mx-auto">
            <ButtonLink to="/account/login" variant="primary" size="lg">LOGIN</ButtonLink>
            <ButtonLink to="/account/register" variant="secondary" size="lg">REGISTER</ButtonLink>
          </div>
        </Container>
      </Container>
    </>
  );
}
