import {useRouteError} from 'react-router-dom';
import Container from "react-bootstrap/Container";
import * as React from "react";

export default function ErrorPage() {
  const error = useRouteError() as { statusText?: string; message?: string; };

  console.error(error);

  return (
    <Container className="p-3">
      <Container className="p-5 mb-4 bg-light rounded-3">
        <h1 className="header align-text-center">SAAS BP</h1>
        <p className="align-text-center">Ooops,something went wrong!</p>
        <p className="align-text-center"><i className="align-text-center">{error.statusText || error.message}</i></p>
      </Container>
    </Container>
  )
}
